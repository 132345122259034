import axios from 'axios';

const state = {
  offers: [],
  addInvoiceModal: false, // Modal visibility state for Add Invoice
  invoiceData: null, // Placeholder for any initial data
  offerDetails: null, // Store the fetched offer details
  currentOffer: null, // Store the current clicked tirage
};

const getters = {
  offers: (state) => state.offers,
  isLoading: (state) => state.isLoading,
  addInvoiceModal: (state) => state.addInvoiceModal,
  invoiceData: (state) => state.invoiceData, // Getter for invoice data if needed
  offerDetails: (state) => state.offerDetails, // Getter for the offer details
  currentOffer: (state) => state.currentOffer,
};

const mutations = {
  SET_OFFERS(state, offers) {
    state.offers = offers;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ADD_INVOICE_MODAL(state, status) {
    state.addInvoiceModal = status; // Toggle modal visibility
  },
  SET_INVOICE_DATA(state, data) {
    state.invoiceData = data; // Set invoice data if needed
  },
  CLOSE_ADD_INVOICE_MODAL(state) {
    state.addInvoiceModal = false;
    state.invoiceData = null; // Clear invoice data when closing
  },
  SET_OFFER_DETAILS(state, offer) {
    state.offerDetails = offer; // Set the offer details
  },
  SET_CURRENT_OFFER(state, offer) {
    state.currentOffer = offer; // Set the clicked tirage
  },
};

const actions = {
  async fetchOffers({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/offers', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      commit('SET_OFFERS', response.data.offers);
    } catch (error) {
      console.error('Error fetching tirages:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  openInvoiceModal({ commit }, invoiceData) {
    commit('SET_CURRENT_OFFER', invoiceData); // Optionally pass some data to the modal
    commit('SET_ADD_INVOICE_MODAL', true); // Show modal
  },
  closeInvoiceModal({ commit }) {
    commit('CLOSE_ADD_INVOICE_MODAL'); // Close modal
  },

  async createOffer({ commit }, invoiceData) {
    try {
      const token = localStorage.getItem('token'); // Use token for authentication
      const response = await axios.post(
        'https://apidash.smartpress.kz/api/offers', // Replace with your API endpoint
        invoiceData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Ensure correct content type
          },
        }
      );
      console.log('Offer created:', response.data);

      // Optionally, you can fetch updated data or show a success notification here
    } catch (error) {
      console.error('Error creating offer:', error);
      throw error; // Re-throw the error to handle in the component if needed
    }
  },

  async fetchOfferDetails({ commit }, offerId) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/offers/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_OFFER_DETAILS', response.data);
    } catch (error) {
      console.error('Error fetching offer details:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateOffer({ dispatch }, offerData) {
    try {
      const token = localStorage.getItem('token');
      const offerId = offerData.offer_id; // Access the offer ID directly

      // Send PUT request to update the offer
      const response = await axios.post(
        `https://apidash.smartpress.kz/api/offers/${offerId}`,
        offerData, 
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Offer updated:', response.data);
      await dispatch('fetchOffers'); // This will update the offers list in the state
    } catch (error) {
      console.error('Error updating offer:', error);
      throw error;
    }
  },
 
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
