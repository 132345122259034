<template>
  <Dropdown classMenuItems="w-[180px] top-[58px]">
    <div class="flex items-center">
      <div class="flex-1 mr-[10px]">
        <div class="lg:h-8 lg:w-8 h-7 w-7 rounded-full">
          <img
            :src="userAvatar"
            alt="User Avatar"
            class="block w-full h-full object-cover rounded-full"
          />
        </div>
      </div>
      <div
        class="flex-none text-slate-600 dark:text-white text-sm font-normal items-center lg:flex hidden overflow-hidden text-ellipsis whitespace-nowrap"
      >
        <span
          class="overflow-hidden text-ellipsis whitespace-nowrap w-[85px] block"
          >{{ userName }}</span
        >
        <span class="text-base inline-block ml-[10px]"
          ><Icon icon="heroicons-outline:chevron-down"></Icon
        ></span>
      </div>
    </div>
    <template #menus>
      <MenuItem v-slot="{ active }" v-for="(item, i) in ProfileMenu" :key="i">
        <div
          type="button"
          :class="`${
            active
              ? 'bg-slate-100 dark:bg-slate-700 dark:bg-opacity-70 text-slate-900 dark:text-slate-300'
              : 'text-slate-600 dark:text-slate-300'
          } `"
          class="inline-flex items-center space-x-2 w-full px-4 py-2 first:rounded-t last:rounded-b font-normal cursor-pointer"
          @click="item.link"
        >
          <div class="flex-none text-lg">
            <Icon :icon="item.icon" />
          </div>
          <div class="flex-1 text-sm">
            {{ item.label }}
          </div>
        </div>
      </MenuItem>
    </template>
  </Dropdown>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { MenuItem } from "@headlessui/vue";
import Dropdown from "@/components/Dropdown";
import Icon from "@/components/Icon";

export default {
  components: {
    Icon,
    Dropdown,
    MenuItem,
  },
  setup() {
    const router = useRouter();

    // Get user data from localStorage
    const activeUser = JSON.parse(localStorage.getItem('activeUser')) || {};
    const userName = ref(activeUser.name || 'Guest'); // Fallback to 'Guest' if no name
    const userRole = ref(activeUser.role_id || null); // Get user role from activeUser
    const userAvatar = ref(activeUser.avatar 
      ? `https://apidash.smartpress.kz/storage/${activeUser.avatar}` 
      : require('@/assets/images/all-img/user.png') // Default avatar
    );

    console.log('User Role:', userRole.value); // Log user role to check it

    const ProfileMenu = computed(() => [
      {
        label: "Profile",
        icon: "heroicons-outline:user",
        link: () => {
          if (userRole.value === 9) {
            router.push("/app/author/profile");
          } else {
            router.push("/app/profile");
          }
        },
      },
      {
        label: "Faq",
        icon: "heroicons-outline:information-circle",
        link: () => {
          router.push("/app/faq");
        },
      },
      {
        label: "Logout",
        icon: "heroicons-outline:login",
        link: () => {
          router.push("/");
          localStorage.removeItem("activeUser");
        },
      },
    ]);

    return {
      ProfileMenu,
      userName,
      userAvatar,
    };
  },
};
</script>

<style lang=""></style>
