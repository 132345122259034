// store/modules/kanban.js
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    
    columModal: false,
    taskModal: false,
    isLoading: null,
    openTaskId: null,
    columns: [
      {
        id: uuidv4(),
        name: 'РИСО/Главный редактор',
        color: '#4669FA',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Проектный менеджер',
        color: '#4669FA',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Редакционные работы',
        color: '#FA916B',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Типография(сигнальный)',
        color: '#FA916B',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Типография(тираж)',
        color: '#00C793',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Отдел продаж',
        color: '#50C700',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Склад(типография)',
        color: '#50C003',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Сдан',
        color: '#50C003',
        techcards: [],
      },
    ],
    isEditTechCardModalVisible: false,
    selectedTechCardForEdit: null,
  },
  getters: {
    columns: (state) => state.columns,
    isEditTechCardModalVisible: (state) => state.isEditTechCardModalVisible,
    selectedTechCardForEdit: (state) => state.selectedTechCardForEdit,
    editTechCardId: (state) => state.selectedTechCardForEdit?.id,
  },
  mutations: {
    openEditTechCardModal(state, techCard) {
      state.isEditTechCardModalVisible = true;
      state.selectedTechCardForEdit = techCard;  // Set the tech card data here
      console.log('Vuex mutation: tech card set for edit:', techCard);  // Debugging log
    },
    closeEditTechCardModal(state) {
      state.isEditTechCardModalVisible = false;
      state.selectedTechCardForEdit = null;
    },
    setColumns(state, techcards) {
      state.columns.forEach((column) => (column.techcards = []));
      techcards.forEach((techcard) => {
        if (techcard.is_editor) {
          state.columns.find((col) => col.name === 'РИСО/Главный редактор').techcards.push(techcard);
        }
        if (techcard.is_project) {
          state.columns.find((col) => col.name === 'Проектный менеджер').techcards.push(techcard);
        }
        if (techcard.is_process) {
          state.columns.find((col) => col.name === 'Редакционные работы').techcards.push(techcard);
        }
        if (techcard.is_signal) {
          state.columns.find((col) => col.name === 'Типография(сигнальный)').techcards.push(techcard);
        }
        if (techcard.is_typography) {
          state.columns.find((col) => col.name === 'Типография(тираж)').techcards.push(techcard);
        }
        if (techcard.is_account) {
          state.columns.find((col) => col.name === 'Отдел продаж').techcards.push(techcard);
        }
        if (techcard.is_stock) {
          state.columns.find((col) => col.name === 'Склад(типография)').techcards.push(techcard);
        }
        if (techcard.is_done) {
          state.columns.find((col) => col.name === 'Сдан').techcards.push(techcard);
        }
      });
    },
    updateTechCardState(state, updatedTechcard) {
      const column = state.columns.find((col) => 
        col.techcards.some((card) => card.id === updatedTechcard.id)
      );
      if (column) {
        const techcard = column.techcards.find((card) => card.id === updatedTechcard.id);
        if (techcard) {
          Object.assign(techcard, updatedTechcard);
        }
      }
    },
  
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state, error) {
      toast.error(`Error: ${error}`, { timeout: 2000 });
    },
  },
  actions: {
    async openEditTechCardModal({ commit }, techCardId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://apidash.smartpress.kz/api/tech-cards/${techCardId}`,
          {headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'  // Ensure this header is present
          }
      });
      console.log("Fetched Tech Card Data:", response.data);  // Debugging log
        commit('openEditTechCardModal', response.data);
      } catch (error) {
        console.error('Failed to fetch tech card data:', error);
        commit('openEditTechCardModal', { id: techCardId });  // If no data, open the modal with empty fields
      }
    },
    closeEditTechCardModal({ commit }) {
      commit('closeEditTechCardModal');
    },
    async fetchTechCards({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        await dispatch('techcards/fetchTechCards', null, { root: true });
        commit('setColumns', this.getters['techcards/techcards']);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('setLoading', false);
      }
    },
    async updateTechCardKanban({ commit }, { techcardId, updatedProperties }) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`https://apidash.smartpress.kz/api/tech-cards/kanban/${techcardId}`, updatedProperties, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'  // Ensure this header is present
          }
        });
    
        commit('updateTechCardState', response.data.techcard);
        toast.success('TechCard updated successfully', { timeout: 2000 });
      } catch (error) {
        commit('setError', error);
        toast.error('Failed to update TechCard', { timeout: 2000 });
      }
    }
  },
};
