import axios from 'axios';

const state = {
  techcards: [],
  editionTypes: [],
  projectManagers: [],
  workTypes: [],
  authors: [],
  application: null,
  techcardForEdit: null,
  loading: false,
  error: null,
  addModal: false,
  editModal: false,
  signalModal: false,
  editSignal: null,
  printModal: false,
  editPrint: null,
  changeStatusModal: false,
  applicationId: null, // Add this to store the application ID
};

const getters = {
  techcards: (state) => state.techcards,
  editionTypes: (state) => state.editionTypes,
  projectManagers: (state) => state.projectManagers,
  workTypes: (state) => state.workTypes,
  authors: (state) => state.authors,
  application: (state) => state.application,
  techcardForEdit: (state) => state.techcardForEdit,
  loading: (state) => state.loading,
  error: (state) => state.error,
  addModal: (state) => state.addModal,
  editModal: (state) => state.editModal,
  signalModal: (state) => state.signalModal,
  editSignal: (state) => state.editSignal,
  printModal: (state) => state.printModal,
  editPrint: (state) => state.editPrint,
  changeStatusModal: (state) => state.changeStatusModal,
};

const actions = {
  async fetchTechCards({ commit }) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/tech-cards', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('setTechCards', response.data.techcards);
      console.log("Fetched Techcards:", response.data.techcards);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchInitialData({ commit }, applicationId) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/tech-cards/create?applicationId=${applicationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('setEditionTypes', response.data.editionTypes);
      commit('setProjectManagers', response.data.projectManagers);
      commit('setWorkTypes', response.data.workTypes);
      commit('setAuthors', response.data.authors);
      commit('setApplication', response.data.application);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async createTechCard({ commit, dispatch }, techCardData) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://apidash.smartpress.kz/api/tech-cards/create', techCardData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      dispatch('fetchTechCards'); // Fetch the latest list after creation
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateTechCard({ commit, dispatch }, { formData, id }) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://apidash.smartpress.kz/api/tech-cards/${id}/edit`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
          // 'Content-Type': 'multipart/form-data', // Ensure the content type is correct for FormData
        }
      });
      dispatch('fetchTechCards'); // Fetch the latest list after creation
    } catch (error) {
      commit('setError', error);
      throw error;
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchTechcardForEdit({ commit }, techcardId) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/tech-cards/${techcardId}/edit`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('setEditionTypes', response.data.editionTypes);
      commit('setProjectManagers', response.data.projectManagers);
      commit('setWorkTypes', response.data.workTypes);
      commit('setAuthors', response.data.authors);
      commit('setTechcardForEdit', response.data.techCard);
      commit('setEditModal', true);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  openTechCardApplication: async ({ dispatch, commit }, applicationId) => {
    // Fetch initial data before opening the modal
    await dispatch('fetchInitialData', applicationId);
    
    // Now open the modal
    commit('SET_APPLICATION_ID', applicationId);
    commit('setAddModal', true);
  },
  closeTechCardModal({ commit }) {
    commit('setAddModal', false);
    commit('setEditModal', false);
  },
  openChangeStatusModal({ commit }) {
    commit('setChangeStatusModal', true);
  },
  closeChangeStatusModal({ commit }) {
    commit('setChangeStatusModal', false);
  },
  openSignalCopy({ commit }, { techCardId, signalData }) {
    commit('setSignalModal', true); // This is correct since it's inside the module
    commit('setEditSignal', { techCardId, signalData });
  },
  closeSignalCopy({ commit }) {
    commit('setSignalModal', false);
  },
  openPrintModal({ commit }, { techCardId, signalData }) {
    commit('setPrintModal', true);
    commit('setEditPrint', { techCardId, signalData });
  },
  closePrintModal({ commit }) {
    commit('setPrintModal', false);
  },
};

const mutations = {
  setTechCards(state, techcards) {
    state.techcards = techcards;
  },
  setEditionTypes(state, editionTypes) {
    state.editionTypes = editionTypes;
  },
  setProjectManagers(state, projectManagers) {
    state.projectManagers = projectManagers;
  },
  setWorkTypes(state, workTypes) {
    state.workTypes = workTypes;
  },
  setAuthors(state, authors) {
    state.authors = authors;
  },
  setApplication(state, application) {
    state.application = application;
  },
  setTechcardForEdit(state, techcard) {
    state.techcardForEdit = techcard;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
  SET_APPLICATION_ID(state, applicationId) {
    state.applicationId = applicationId; // Store the application ID
  },
  setAddModal(state, status) {
    state.addModal = status;
  },
  setSignalModal(state, status) {
    state.signalModal = status;
  },
  setPrintModal(state, status) {
    state.printModal = status;
  },
  setEditSignal(state, { techCardId, signalData }) {
    state.editSignal = { techCardId, signalData };
  },
  setEditPrint(state, { techCardId, signalData }) {
    state.editPrint = { techCardId, signalData };
  },
  setEditModal(state, editModal) {
    state.editModal = editModal;
  },
  setChangeStatusModal(state, changeStatusModal) {
    state.changeStatusModal = changeStatusModal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
